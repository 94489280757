import React, {useState} from "react"
import { FaEnvelope, FaLock } from 'react-icons/fa'
import {useDispatch} from "react-redux"
import {navigate} from "gatsby"

import "./login.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import ResetPassword from "../containers/resetPassword/resetPassword"
import {login} from "../redux/api/apiAction"
import {loginCoordinator} from "../redux/group/groupAction";


const LoginPage = () => {
    const dispatch = useDispatch()

    const [success, setSuccess] = useState(false)
    const [error, setError]     = useState(false)
    const [isCoordinator, setIsCoordinator] = useState(false)

    const [password, setPassword]   = useState(process.env.NODE_ENV !== 'production' ? "sdf" : "")
    const [email, setEmail]         = useState(process.env.NODE_ENV !== 'production' ? "antoine.delagarcie@gmail.com" : "")

    const [validationPassword, setValidationPassword]   = useState("")
    const [validationEmail, setValidationEmail]         = useState("")

    const onChange = e => {
        const {name, value} = e.target
        name === "email" ? setEmail(value) : setPassword(value)
    }

    const checkValidation = () => {
        if(email === "")
            setValidationEmail("Enter your email")
        else
            setValidationEmail("")

        if(password === "")
            setValidationPassword("Enter your password")
        else
            setValidationPassword("")

        return email.length > 0 && password.length > 0
    }

    const onSubmit = e => {
        e.preventDefault()

        if(checkValidation()) {
	            if(isCoordinator){
                dispatch(loginCoordinator(email, password))
                    .then(res => {
                        if(res === true) {
                            setEmail("")
                            setPassword("")
                            setSuccess(true)
                            setError(false)
                            navigate("/group")
                        }
                        else {
                            setSuccess(false)
                            setError(true)
                        }
                    })
            }
            else {
                dispatch(login(email, password))
                    .then(res => {
                        if(res === true) {
                            setEmail("")
                            setPassword("")
                            setSuccess(true)
                            setError(false)
                            navigate("/individual")
                        }
                        else {
                            setSuccess(false)
                            setError(true)
                        }
                    })
            }
        }
        else {
            setSuccess(false)
            setError(true)
        }
    }

    return (
        <Layout main={false} className={"non-member-single"}>
            <SEO title="Registration 2021" />
            <Header/>
            <div className={"col-10 mx-auto"}>                        
            
                <p>Registration is closed.</p>
                        
            </div>
        </Layout>
    )
}

export default LoginPage
